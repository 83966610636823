.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


body{
  background-color: #ffffff;
  margin: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
*{
  margin: 0;
  padding: 0;
}
  .bg-blue{
  background-color:#020E2A ;
  } 
  .bg-dark-blue{
    background-color:#01081A ;
  } 
  .bg-gray{
    background-color:#E4E4E4 ;
    /* background-color:#DDDDDD ; */
  } 
  .bg-transparent{
    background-color:transparent ;
  }
 
  .txt-blue{
    color:#020E2A ;
  } 
  .txt-dark-blue{
    color:#01081A ;
  } 
    .txt-yellow{
    color:#FAB909 ;
  } 
  .txt-white{
    color:#ffffff ;
  }
  .txt-black{
    color:#212121 ;
  } 
 .txt-gray{
  color:#020e2a42 ;
 } 

.res-text p{
  font-size: clamp(0.8rem, 2vw , 1rem);
  color: #212121;
  letter-spacing: 0.03em;
}
.res-medium-text p,
.res-medium-text dd,
.res-medium-text td,
.res-medium-text li{
  font-size: clamp(0.8rem, 1.75vw, 0.9rem); 
  color: #212121;
  letter-spacing: 0.04em;
}
.res-small-text p,
.res-small-text li,
.res-small-text td{
  font-size: clamp(0.7rem, 1.5vw, 0.9rem);
  color: #212121;
  letter-spacing: 0.05em;
}
.link-text{
  font-size:  clamp(0.6rem, 1vw , 1rem);
  font-weight: 500;
  color: white;
  margin-inline: 4px;
  text-decoration: none;
}
.link-text:hover{
  font-weight: 600;
  color: #FAB909;
  transform: scale(1.1);
}
.navbar-button-text{
  font-size:  clamp(0.6rem, 1vw , 1rem);
  font-weight: 500;
  color: white;
  margin-inline: 4px;
  text-decoration: none;
}
.navbar-button-text:hover{
  font-weight: 600;
  color: #ffffff;
  transform: scale(1.1);
}
.navbar-image{
  /* width: clamp(180px,25%,100px);*/
  height: 70px;
  width: 180px;
  object-fit: contain;
}
.navbar-item{
  font-size: 14px;
  font-weight: 500;
  color: #5a5d5e;
  margin: 0px 20px;
  transition: all 0.3s ease;
}
.navbar-item:hover{
  font-weight: 600;
  color: #212121;
  transform: scale(1.1);
}
.carousel-image{
  width: 100vw;
  height: 100vh; 
  max-height: 550px;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1;
  object-fit: cover;
  filter:brightness(50%);
  /* filter: blur(5px); */
    /* filter:brightness(200%); */
    /* filter: contrast(200%); */
    /* filter: grayscale(100%); */
    /* filter: saturate(200%); */
}
.carousel-caption-container{
  position: absolute;
  top: 45%;
  left: 10%; 
  transform: translateY(-50%);
  text-align: left;
}
.content-container{
  padding-inline: 100px;
}
@media(max-width: 1224px){
  .content-container{
    padding-inline: 50px;
  }
}
.service1{
  background-color: transparent;
  width: 17rem;
  border: none;
}
@media(max-width: 1224px){
  .service1{
    background-color: transparent;
    width: 23rem;
    border: none;
  }
}
.service2{
  background-color: #DDDDDD;
  width: 20rem;
  border: none;
  transition: transform 0.3s ease;
  position: relative;
  overflow: hidden;
  flex-direction: row;
}
.service2:hover {
  transform: scale(1.03);
}
.service2::before {
  content: "";
  position: absolute;
  bottom: -100%; 
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(250, 185, 9,0.9); 
  transition: bottom 0.4s ease;
  z-index: 1; 
  mix-blend-mode: multiply;
}

.service2:hover::before {
  bottom: 0; 
}
.service2 .content {
  position: relative;
  z-index: 2; 
  padding: 20px;
  color: #333;
}
.service3{
  background-color: transparent;
  width: 28rem;
  border: none;
  flex-direction: row;
}
.input{
  background-color: transparent;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #5a5d5e;
  color: #212121 !important;
  padding-inline: 0;
  font-size:  clamp(0.7rem, 1.5vw, 0.9rem);
  /* width: 240px; */
 
}
.input::placeholder{
  color:#5a5d5e !important;
}
.input:focus{
  box-shadow: none;
  background-color: transparent;
  border-bottom: 1px solid #212121;
  outline: none;
}
.dropdown {
  background-color: transparent;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #212121;
  color: #5a5d5e;
  font-size:  clamp(0.7rem, 1.5vw, 0.9rem);
  /* width: 240px; */
  padding-inline: 0px;
}
.dropdown .dropdown-toggle {
  color: #212121;
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-size: clamp(0.7rem, 1.5vw, 0.9rem);
  padding-inline: 0px;
}
.dropdown .dropdown-toggle::after {
  margin-left: 7px;
  color: #212121;
}
.dropdown .dropdown-menu {
  border: 1px solid #5a5d5e;
  border-top: none;
}
.home-image{
  background-image: url('../src/assets/images/security/h2.jpg');
  background-size: cover; 
  background-position: center;
  width: 100%;
  height: 100%; 
  position: relative;
  overflow: hidden;
  }
.home-image .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  /* background-color: rgba(0, 0, 0, 0.5); */
  width: 100%;
  height: 100%; 
  display: flex;
  align-items: center;
  justify-content: center; 
  z-index: 1;
  /* overflow: hidden;  */
 }
 .footer-image{
  height: 80px;
  width: 200px;
  object-fit: contain;
}

.link-small-text{
  font-size: clamp(0.7rem, 0.6vw, 0.7rem);
  font-weight: 500;
  color: white;
  margin-inline: 4px;
  text-decoration: none;
}
.footer-link-text{
  font-size:  clamp(0.5rem, 0.9vw , 1rem);
  font-weight: 500;
  color: white;
  margin-inline: 4px;
  text-decoration: none;
}
.footer-link{
  font-size:  clamp(0.5rem, 0.9vw , 1rem);
  font-weight: 500;
  color: white;
  margin-inline: 4px;
  text-decoration: none;
}
.footer-link:hover{
  text-decoration:underline;
}
  .background-container {
    position: relative;
    width: 100vw;
    height: 200px; 
    background-image: url('./assets/images/security/carousal1.jpg'); 
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; 
    object-fit: contain;
  }
  .background-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #020e2ac1;
    z-index: 1; 
  }
  .text-overlay {
    position: relative;
    z-index: 1;
    color: white; 
    text-align: center;
  }
  .badge-style {
    background-color: #01081A;
    border-width: 0.5px;
    border-style:solid;
    border-radius: 100px;
    border-color:#FAB909 ;
    padding-inline: 20px;
    padding-block: 10px;
  }
  .main-card {
    width: 280px;
    border-radius: 10px;
    border-width: 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  }
  .main-card-img {
    border-radius: 10px;
    height: 160px;
    width: 100%;
    border-bottom-left-radius:0 ;
    border-bottom-right-radius:0 ;
  }
  .res-heading {
    font-size: clamp(0.8rem, 1vw , 1rem);
    color: #212121;
    letter-spacing: 0.03em;
  }
  .one-line-text {
    display: -webkit-box;
    -webkit-line-clamp: 1; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; /* Optional for text overflow indication */
  }
  .res-readMore {
    font-size: clamp(0.5rem, 0.9vw , 1rem);
    color: #277bdb;
    letter-spacing: 0.03em;
  }
  .subcriber-card {
    border-radius: 10px;
    border-width: 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    justify-content:space-between;
    align-items: center;
    display: flex;
    flex-direction: row;
  }
  .dashboard-card {
    width: 100%;
    height: 100px;
    border-radius: 10px;
    border-width: 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.07);
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    flex-direction: row;
  }
