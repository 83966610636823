/*ADMIN STYLES START*/
.resText {
  font-size: clamp(0.8rem, 2vw, 1rem);
}
.White-Color-Text {
  color: #ffffff;
}
.Black-Color-Text {
  color: #212121;
}
.Gradient-Dark {
  color: #ff6b40;
}
.Gradient-Medium {
  color: #ff504b;
}
.Gradient-Light {
  color: #e2b5a5;
}
.Gray-Contrast {
  color: #e2b5a5;
}
.Yellow-Color-Text {
  color: #ffea08;
}
.Heading-Text {
  color: #4a556b;
}
.Detail-Text {
  color: #8b99b9;
}

.Gradient-Text {
  background: linear-gradient(to right, #ff4501 0%, #ff504b 50%, #fa936e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: calc();
}
.Gradient-Background-New {
  background: linear-gradient(-80deg, #ff4501 0%, #ff504b 50%, #e74003 100%);
  width: 100%;
  height: auto;
}
.Gradient-Background-Button {
  background: linear-gradient(-80deg, #ff4501 0%, #ff504b 50%, #ff865a 100%);
}
.lightGradient-Background {
  background: linear-gradient(
    to bottom,
    rgba(216, 189, 236, 0) 0%,
    /* Fully transparent at the top */ rgba(216, 189, 236, 0.3) 20%,
    /* Start of solid color */ rgba(216, 189, 236, 0.5) 50%,
    /* Middle solid color */ rgba(216, 189, 236, 0.3) 80%,
    /* End of solid color */ rgba(216, 189, 236, 0) 100%
  ); /* Fully transparent at the bottom */
  width: 100%;
  height: auto;
}
.parallelograph {
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 94%);
}
.parallelographTopBottom {
  clip-path: polygon(0 0, 100% 18%, 100% 100%, 0 84%);
}

.textCenter {
  text-align: "center";
}

.slick-slide > div {
  margin: 0 10px;
}

.slick-list {
  margin: 0 -10px;
}

.slick-slide *:focus {
  outline: none;
}

.blurEchoCard {
  width: 22rem;
  height: auto;
  margin-top: 12vh;
  border: none;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  text-align: center;
  backdrop-filter: blur(10px);
}
.blurEchoCard:hover {
  transform: scale(1.1, 1.1);
  backdrop-filter: blur(0px);
}
.echoImage {
  width: clamp(80px, 25%, 100px);
  border-radius: 50%;
  background-color: #f3def6;
  position: absolute;
  top: -45px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  object-fit: cover;
}

.NewEchoCard {
  width: 22rem;
  height: auto;
  margin-top: 8vh;
  border: none;
  box-shadow: 0 4px 15px rgba(255, 255, 255, 0.4);
  background: #fcfcfd;
  border-radius: 15px;
  text-align: center;
  backdrop-filter: blur(10px);
}
.NewEchoCard:hover {
  transform: scale(1.1, 1.1);
  backdrop-filter: blur(0px);
}
.NewImage {
  width: clamp(80px, 25%, 100px);
  border-radius: 50%;
  background-color: #f3def6;
  position: absolute;
  top: -45px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
  object-fit: contain;
}

.stageCard {
  max-width: 22rem;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.1);
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.15) 0%,
    rgba(255, 255, 255, 0.05) 100%
  );
  transition: box-shadow 0.5s ease, border-radius 0.5s ease;
  box-shadow: inset 0 -1.5em 2em rgba(130, 132, 217, 0.2),
    inset 1.5em 0 2em rgba(175, 153, 191, 0.15),
    inset -1.5em 0 2em rgba(226, 181, 165, 0.15);
}
.stageCard:hover {
  box-shadow: inset 0 -1.5em 1.5em rgba(130, 132, 217, 0.05),
    inset 1.5em 0 2em rgba(175, 153, 191, 0.2),
    inset -1.5em 0 2em rgba(226, 181, 165, 0.4), 0 0 1em rgba(0, 0, 0, 0.1);
  transform: scale(1.05, 1.05);
}

.codepen-button {
  color: #212121;
  margin: 0 auto;
  position: relative;
  text-decoration: none;
  font-weight: 600;
  border-radius: 100px;
  overflow: hidden;
  padding: 3px;
  isolation: isolate;
  border-width: 0;
}
.codepen-button :hover {
  color: #8284d9;
}

.codepen-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 300%;
  height: 100%;
  background: linear-gradient(115deg, #8284d9, #e2b5a5, #af99bf, #e2b5a5);
}
.codepen-button span {
  position: relative;
  display: block;
  padding: 0.7rem 1.2rem;
  font-size: 1rem;
  background: #eef3fa;
  border-radius: 100px;
  height: 100%;
}
.polygon-cardd {
  width: 100%;
  height: auto;
  padding: 30px;
  justify-content: center;
  border-radius: 30px;
  align-items: center;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  background: linear-gradient(135deg, #ff6b40 0%, #ff504b 50%, #fa936e 100%);
  backdrop-filter: blur(10px);
}
@media (min-width: 1224px) {
  .polygon-card {
    clip-path: polygon(
      30% 0%,
      70% 0%,
      100% 30%,
      100% 70%,
      70% 100%,
      30% 100%,
      0% 70%,
      0% 30%
    );
    padding: 10%;
  }
}
@media (max-width: 475px) {
  .polygon-card {
    border-width: 2px;
    border-color: #eef3fa;
    border-style: solid;
    border-radius: 12px;
    padding: 5%;
  }
}
.drop-shadow {
  filter: drop-shadow(2px 4px 8px #585858);
}

.row-content-text {
  color: #b7b98b;
}
.faq-row {
  border: 1px solid #af99bf;
  border-radius: 8px;
  margin-bottom: 15px;
}
.row-title {
  margin-left: 15px;
}
.fullCenterContainer {
  justify-content: center;
  align-items: center;
}

/* From Uiverse.io by vinodjangid07 */
.button {
  width: 160px;
  height: 40px;
  background-image: #ffffff;
  border: none;
  border-radius: 50px;
  color: #ff6b40;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  box-shadow: 1px 3px 0px rgba(9, 7, 18, 0.2);
  transition-duration: 0.3s;
}

.button:active {
  transform: translate(2px, 0px);
  box-shadow: 0px 1px 0px rgb(239, 239, 242, 0.4);
  padding-bottom: 1px;
}
.button:hover {
  color: #282c34;
}
.sideNav-card {
  background-color: #e5e5e5;
  /* background-color: #EEECEC; */
  border: none;
}
.sideNav-card-active {
  background-color: #e5e5e5;
  /* background-color: #EEECEC; */
  border-width: 1px;
  border-style: solid;
  border-color: #d3d5d5;
  box-shadow: 0 0px 4px 1px rgba(0, 0, 0, 0.1);
}
.sideNav-card:hover {
  background-color: #e5e5e5;
  /* background-color: #eeecec; */
  border-width: 1px;
  border-style: solid;
  border-color: #d3d5d5;
  box-shadow: 0 0px 4px 1px rgba(0, 0, 0, 0.1);
}
.airDrop-waring-card {
  /* background-color:rgba(226, 193, 193, 0.1); */
  width: 80%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 0px 4px 3px rgba(40, 15, 15, 0.07);
  padding: 7%;
  border: none;
  margin-left: 50px;
}
.airDrop-card {
  background-color: rgba(226, 193, 193, 0.1);
  /* background-color:rgb(238, 236, 236,0.3); */
  width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 0px 4px 3px rgba(40, 15, 15, 0.07);
  padding: 5%;
  border: none;
}
.airDrop-card-Button {
  background-color: rgba(245, 5, 5, 0.03);
  color: #f85a2e;
  border-color: rgba(245, 5, 5, 0.3);
  border-radius: 10px;
  text-decoration: none;
  padding: 6px 10px;
  /* padding: 10px 15px; */
  height: 20%;
}
.airDrop-card-Button:hover {
  background-color: rgba(245, 5, 5, 0.1);
  color: #f85a2e;
  border-color: rgba(245, 5, 5, 0.1);
}

.dashboardCard {
  width: 18rem;
  /* height: 100px; */
  height: auto;
  margin-top: 30px;
  border: none;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  /* text-align: center;  */
  backdrop-filter: blur(10px);
  /* z-index: 2; */
  padding: 10px;
}

.cardForLandingPage {
  /* width: 18rem; */
  /* height: 100px; */
  height: auto;
  margin-top: 30px;
  border: none;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  /* background: rgb(207, 84, 84); */
  border-radius: 15px;
  /* text-align: center;  */
  backdrop-filter: blur(10px);
  /* z-index: 2; */
  padding: 10px;
}

.dashboardImage {
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  height: auto;
  /* filter: saturate(150%); */
  /* filter: brightness(150%); */
}
.presale-card {
  height: auto;
  border-radius: 12px;
  box-shadow: 0 0px 4px 3px rgba(40, 15, 15, 0.07);
  padding-inline: 3%;
  padding-block: 4%;
  border: none;
  margin-bottom: 15px;
}
.my-button {
  background-color: #3fc3ee;
  color: #fff;
  border: none;
  padding: 10px 50px;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s;
}
.my-button:hover {
  background-color: #34b5e0;
}
.success-button {
  background-color: #22a716;
  color: #fff;
  border: none;
  padding: 10px 50px;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s;
}
.success-button:hover {
  background-color: #126c09;
}
.roadMapStatus {
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  font-size: 12px;
  padding-block: 0px;
  height: 35px;
}
.teamCard {
  width: 18rem;
  height: auto;
  border: none;
  background: transparent;
}
.submitPost_button {
  border: none;
  background: #171717;
}
.submitPost_button:hover {
  border: none;
  background: #171717bd;
}

/*ADMIN STYLES END*/
